import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import httpClient from '../httpClient';
import { Container } from 'react-bootstrap';

const DialogMessage = (props) => {
    const messageContent = props.messageContent;

    return (
        <Card elevation={2} >
            {/* <Typography variant='body1' gutterBottom>{messageContent}</Typography> */}
            <CardContent>{messageContent}</CardContent>
        </Card>
    )
}

const ChatBot = (props) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const sendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = { sender: 'user', text: input };
        setMessages([...messages, userMessage]);

        try {
            const response = await httpClient.post('/user/dialogflow', { question: input });
            // Right now we assume only one message
            const messageContent = response.data.message.fulfillmentMessages[0]?.text.text[0];
            const botMessage = { sender: 'bot', text: messageContent };
            setMessages([...messages]);
        } catch (error) {
            console.error('Error sending message to Dialogflow', error);
        }

        setInput('');
    }
    return (

        <>
            <Container maxwidth="xs" style={{
                display: "flex", alignItems: "center", flexDirection: "column", height: "300px",
                justifyContent: "center", overflowY: "auto"
            }}>
                <Stack spacing={2}
                    style={{ width: "inherit" }}
                >
                    {messages.map((msg, index) => (
                        // <div key={index} className={msg.sender}>
                        //     {msg.text}
                        // </div>
                        <CardContent>
                            <Typography variant='body1'>
                                <DialogMessage key={index} sender={msg.sender} messageContent={msg.text} />
                            </Typography>
                        </CardContent>
                    ))}
                </Stack>
                <Divider sx={{ margin: '12px' }} />
                <TextField
                    multiline
                    fullWidth
                    label="Virtual Assistant"
                    placeholder="Please enter your query here"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
            </Container>
            <div style={{
                display: "flex", alignItems: "center", flexDirection: "column",
                justifyContent: "center"
            }}>
                <Button style={{ marginTop: "8px" }} variant='contained' endIcon={<SendIcon />} onClick={sendMessage}>Send</Button>
            </div>
        </>
    )

}
export default ChatBot;