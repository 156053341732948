import httpClient from "../../httpClient";

async function getMapsAPIToken() {
    try {
        const response = await httpClient.get("/tokens/maps-api");
        return response.data.data;
    } catch(e) {
        console.log("Error getting maps API token:");
        console.log(e);
        return null;
    }
}

export default getMapsAPIToken;

export const RUNNING_STATE_COLORS = {
    atRest: '#777777',
    inMotion: '#1362AC',
    shuttingDown: '#B32323',
    default: '#1462AC'
};