import React from 'react';
import {
    Button,
    Container,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';

import httpClient from '../httpClient';

const HelpRequestForm = () => {

    const validationSchema = yup.object({
        platform: yup.string().required('Platform is required'),
        summary: yup.string().required('Summary is required'),
        description: yup.string().required('Description is required'),
    });

    const initialValues = {
        platform: 'web',
        summary: '',
        description: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            httpClient.post('/help-request/', values).then(response => {
                alert('Your help request has been submitted');
                formik.resetForm();
            }).catch(error => {
                console.log("Error submitting help request", error);
                alert('There was an error submitting your request. Please contact us at support@tracmile.com for assistance.');
            });
        }
    });

    return (
        <Container component={Paper} elevation={3} sx={{paddingTop: 2, paddingBottom: 2}}>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                    <Typography variant='h6'>Help Request Form</Typography>
                    <TextField
                        fullWidth
                        hidden
                        id="platform"
                        name="platform"
                        label="Platform"
                        value={formik.values.platform}
                        onChange={formik.handleChange}
                        error={formik.touched.platform && Boolean(formik.errors.platform)}
                        helperText={formik.touched.platform && formik.errors
                            .platform}
                    />

                    <TextField
                        fullWidth
                        id="summary"
                        name="summary"
                        label="Summary"
                        value={formik.values.summary}
                        placeholder='Enter a short summary of your request'
                        onChange={formik.handleChange}
                        error={formik.touched.summary && Boolean(formik.errors.summary)}
                        helperText={formik.touched.summary && formik.errors.summary}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        label="Description"
                        value={formik.values.description}
                        placeholder='Enter detailed information about the nature of your request'
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />
                    <Button variant='contained' color='primary' type='submit'>Submit</Button>
                </Stack>
            </form>
        </Container>
    )
}

export default HelpRequestForm;