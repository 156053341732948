import React from 'react';
import { Button, Typography } from '@mui/material';

import { userCache } from '../routes/ProtectedRoute';
import httpClient from '../httpClient';

const SendEmailConfirmationView = (props) => {
    const userId = props.currentUser?._id;
    const [user, setUser] = React.useState(null);
    const [sentEmail, setSentEmail] = React.useState(false);

    const handleSubmit = async () => {
        if (!user) {
            console.log("User missing for email confirmation api call");
            return;
        }
        try {
            await httpClient.put(
                '/user/sendEmailVerification/' + user._id
            )
            setSentEmail(true);
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        if (!userId) {
            console.log("User ID missing for email confirmation view");
            return;
        }
        if (user) {
            return;
        }
        httpClient.get(
            '/user/' + userId
        ).then(res => {
            setUser(res.data.user);
        }).catch(reason => {
            console.log("Error getting user information");
            console.log(reason);
        });
    }, [userId, user]);

    if (!user) {
        return <Typography variant='h4'>Loading...</Typography>
    }

    if (user.emailVerified) {
        userCache.forceReload();
        return <Typography variant='h4'>Email Address Confirmed! You may now begin using TracMile.</Typography>
    }

    return (
        <>
            <Typography variant='h4'>Email Address Confirmation</Typography>
            <Typography variant='body1'>You must confirm your email address before you can use TracMile.
                Please check your email for the confirmation message, and click the enclosed link to verify your email account.</Typography>
            {sentEmail ? <Typography variant='body2'>Confirmation email sent.</Typography> : <>
                <Typography variant='body2'>If you need to re-send the confirmation message, click the button below.</Typography>
                <Button variant='contained' onClick={handleSubmit}>Resend</Button>
            </>}
        </>
    )
}

export default SendEmailConfirmationView;