import { Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';

import httpClient from '../httpClient';

// Cache configuration
const CACHE_DURATION = 30 * 1000; // 30 seconds
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second
const DEBOUNCE_INTERVAL = 300;

export const userCache = {
    data: null,
    timestamp: null,
    isValid() {
        return (
            this.data &&
            this.timestamp &&
            Date.now() - this.timestamp < CACHE_DURATION
        );
    },
    set(data) {
        this.data = data;
        this.timestamp = Date.now();
    },
    clear() {
        this.data = null;
        this.timestamp = null;
    },
    forceReload() {
        this.clear();
        window.dispatchEvent(new Event('user-cache-invalidated'));
    },
};

// Move retry logic outside component since it doesn't depend on component state
const retryWithBackoff = async (fn, retries = MAX_RETRIES) => {
    try {
        return await fn();
    } catch (error) {
        if (retries === 0) throw error;

        await new Promise(resolve =>
            setTimeout(resolve, RETRY_DELAY * (MAX_RETRIES - retries + 1))
        );

        return retryWithBackoff(fn, retries - 1);
    }
};

const ProtectedRoute = ({skipEmailVerification, children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    // const skipEmailVerification = props.skipEmailVerification;

    useEffect(() => {
        const handleCacheInvalidation = () => {
          setLoading(true);
        };

        window.addEventListener('user-cache-invalidated', handleCacheInvalidation);
        return () => window.removeEventListener('user-cache-invalidated', handleCacheInvalidation);
      }, []);

    useEffect(() => {
        const debouncedLoad = debounce(async () => {
            const userToken = httpClient.getCurrentUser();
            if (!userToken) {
                setLoading(false);
                setUser(null);
                userCache.clear();
                return;
            }

            if (userCache.isValid()) {
                setUser(userCache.data);
                setLoading(false);
                return;
            }

            try {
                const userData = await retryWithBackoff(async () => {
                    const res = await httpClient.get('/user/');
                    return res.data.user;
                });

                userCache.set(userData);
                setUser(userData);
                setError(null);
            } catch (err) {
                console.error("Failed to load user data after retries:", err);
                setError(err);
                userCache.clear();
            } finally {
                setLoading(false);
            }
        }, DEBOUNCE_INTERVAL);

        setLoading(true);
        debouncedLoad();

        return () => debouncedLoad.cancel();
    }, [location.pathname]);

    if (loading) {
        return (
            <div className="flex items-center justify-center p-4">
                <p>Loading user data...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4">
                <p>Error loading user data. Please try again.</p>
                <button
                    onClick={() => setLoading(true)}
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Retry
                </button>
            </div>
        );
    }

    if (!user) {
        httpClient.clearToken();
        userCache.clear();
        return <Navigate to="/login" replace />;
    }

    if (user?.token?.KEY === 'ERR_EXPIRED_TOKEN') {
        httpClient.clearToken();
        userCache.clear();
        return <Navigate to="/login" replace />;
    }

    if (!skipEmailVerification && user && !user.isVerified) {
        return <Navigate to="/must-confirm-email" replace />;
    }

    return children;
};

export default ProtectedRoute;