import React from 'react';
import {
    Routes,
    Route,
    // Link,
    Navigate,
    useLocation,
    // withRouter
} from 'react-router-dom';

import DeviceList from '../panels/devicelist';
// import TopBanner from './panels/topbanner';
import HomePage from '../containers/HomePage';
import LogIn from '../views/LogIn';
import LogOut from '../views/LogOut';
import SignUp from '../views/SignUp';
import UserManage from '../containers/UserManage';
import UserDetail from '../containers/UserDetail';
import ManageFirmwareView from '../views/ManageFirmwareView';
import DeviceDetail from '../containers/DeviceDetail';
import MainDashboard from '../containers/MainDashboard';
import LargeMapView from '../containers/LargeMapView';
import TripHistoryView from '../views/TripHistoryView';
import MyProfileView from '../views/MyProfileView';
import SupportView from '../views/SupportView';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import ForgotPasswordRequestForm from '../components/ForgotPasswordRequestForm';
import EmailConfirmationForm from '../components/EmailConfirmationForm';
import MQTTLogView from '../views/MQTTLogView';
import AccountDeleteView from '../views/AccountDeleteView';
import ConfigureTireSizeView from '../views/ConfigureTireSizeView';
import AssetListView from '../views/AssetListView';
import AssetDetailView from '../views/AssetDetailView';
import AssetTripHistoryView from '../views/AssetTripHistoryView';
import CreateAssetForm from '../components/CreateAssetForm';
import LinkDeviceView from '../views/LinkDeviceView';
import LinkDeviceFlowView from '../views/LinkDeviceFlowView';
import OrganizationManageView from '../views/OrganizationManageView';
import OrganizationSignupView from '../views/OrganizationSignupView';
import InviteUserView from '../views/InviteUserView';
import VerifyEmailForm from '../components/VerifyEmailForm';
import PricingForm from '../components/subscription/PricingForm';
import SubscriptionCheckoutForm from '../components/subscription/SubscriptionCheckoutForm';
import AddPaymentMethod from '../components/subscription/AddPaymentMethod';
import SubscriptionCancelView from '../views/SubscriptionCancelView';
import OrgUserSignupMobile from '../views/OrgUserSignupMobile';
import VerifyEmailView from '../views/VerifyEmailView';
import HelpAndSupportView from '../views/HelpAndSupportView';
import DeviceSettingsView from '../views/DeviceSettingsView';
import SendEmailConfirmationView from '../views/SendEmailConfirmationView';
import LinkHuboFlow from '../components/LinkHubo';

import ProtectedRoute from './ProtectedRoute';

import httpClient from '../httpClient';

// Helper components; TODO: move to a separate file
const ForgotPasswordHelper = (props) => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get('t');
    const email = params.get('email');
    if (!token || !email) {
        return (
            <p>
                Invalid forgot password link. Please use the link in the email
                that was sent.
            </p>
        );
    }
    return <ForgotPasswordForm token={token} email={email} />;
};

const EmailConfirmationHelper = (props) => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get('t');
    const email = params.get('email');
    if (!props.currentUser) {
        const loginLinkWithRedirect = `/login?redirectTo=/confirm-email?t=${token}&email=${email}`;
        return (<Navigate to={loginLinkWithRedirect} />);
    }
    if (!token || !email) {
        return (
            <p>
                Invalid confirmation link. Please use the link in the email that
                was sent.
            </p>
        );
    }
    return <EmailConfirmationForm token={token} email={email} />;
};

// const ProtectedRoute = ({children}) => {
//     const [user, setUser] = React.useState(null);
//     const [loading, setLoading] = React.useState(true);
//     const location = useLocation();

//     React.useEffect(() => {
//     const loadUserData = async () => {
//       const userToken = httpClient.getCurrentUser();
//       if (!userToken) {
//         setLoading(false);
//         return;
//       }

//       try {
//         const res = await httpClient.get('/user/');
//         setUser(res.data.user);
//         console.log("User doc loaded");
//       } catch (err) {
//         console.log("Error loading user doc");
//         console.log(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     setLoading(true);
//     loadUserData();
//   }, [location.pathname]); // Re-run effect when path changes

//     if (loading) {
//         return <p>Loading...</p>;
//     }

//     // sanity check
//     if (!user && !loading) {
//         httpClient.clearToken();
//         return <Navigate to="/login" />;
//     }

//     // check for stale token
//     if (user?.token?.KEY === 'ERR_EXPIRED_TOKEN') {
//         httpClient.clearToken();
//         return <Navigate to="/login" />;
//     }

//     if (user && !user.isVerified) {
//         return <Navigate to="/must-confirm-email" />;
//     }
//     return children;
// };

const AdminProtectedRoute = ({children}) => {
    const user = httpClient.getCurrentUser();
    if (!user) {
        return <Navigate to="/login" />;
    }
    // if (user && !user.isVerified) {
    //     return <Navigate to="/must-confirm-email" />;
    // }
    if (!user.admin) {
        return <Navigate to="/forbidden" />;
    }
    return children;
}

const MainRoutes = (props) => {
    const {
        currentUser,
        onLoginSuccess,
        logOut,
        isAuthenticated
    } = props;
    return (
        <Routes>
            <Route
                path="/login"
                element={<LogIn onLoginSuccess={onLoginSuccess} />}
            ></Route>

            <Route
                path="/logout"
                element={<LogOut onLogOut={logOut} />}
            ></Route>

            {/* the sign up component takes an 'onSignUpSuccess' prop which will perform the same thing as onLoginSuccess: set the state to contain the currentUser */}
            <Route
                path="/signup"
                element={<SignUp onSignUpSuccess={onLoginSuccess} />}
            ></Route>

            <Route
                path="/org_user_signup"
                element={<OrgUserSignupMobile />}
            ></Route>

            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        <MainDashboard user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/help"
                element={<HelpAndSupportView />}
            ></Route>

            <Route
                path="/devicedetail/:deviceID"
                element={
                    // TODO: Device access control
                    <ProtectedRoute>
                        <DeviceDetail user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/usermanage"
                element={
                    <AdminProtectedRoute>
                        <UserManage user={currentUser} />
                    </AdminProtectedRoute>
                }
            ></Route>
            <Route
                path="/userdetail/:userID"
                element={
                    <AdminProtectedRoute>
                        <UserDetail />
                    </AdminProtectedRoute>
                }
            ></Route>

            <Route
                path="/managefirmware"
                element={
                    <AdminProtectedRoute>
                        <ManageFirmwareView />
                    </AdminProtectedRoute>
                }
            ></Route>

            <Route
                path="/devicesettings"
                element={
                    <AdminProtectedRoute>
                        <DeviceSettingsView />
                    </AdminProtectedRoute>
                }
            ></Route>

            <Route
                path="/mqttlog"
                element={
                    <AdminProtectedRoute>
                        <MQTTLogView />
                    </AdminProtectedRoute>
                }
            ></Route>

            <Route
                path="/linkdevice"
                element={
                    <ProtectedRoute>
                        <LinkDeviceView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/linkhubo"
                element={
                    <ProtectedRoute>
                        <LinkDeviceFlowView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/devices"
                element={
                    <AdminProtectedRoute>
                        <DeviceList user={currentUser} />
                    </AdminProtectedRoute>
                }
            ></Route>

            <Route
                path="/assets"
                element={
                    <ProtectedRoute>
                        <AssetListView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/assetdetail/:assetId"
                element={
                    <ProtectedRoute>
                        <AssetDetailView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/create-asset"
                element={
                    <ProtectedRoute>
                        <CreateAssetForm user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/triphistory/:deviceID"
                element={
                    <ProtectedRoute>
                        <TripHistoryView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/assettriphistory/:assetId"
                element={
                    <ProtectedRoute>
                        <AssetTripHistoryView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/mapview"
                element={
                    <ProtectedRoute>
                        <LargeMapView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/alerts"
                element={
                    <ProtectedRoute>
                        <MainDashboard />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/support"
                element={
                    <ProtectedRoute skipEmailVerification>
                        <SupportView />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/profile"
                element={
                    <ProtectedRoute skipEmailVerification>
                        <MyProfileView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/orgmanage"
                element={
                    <ProtectedRoute>
                        <OrganizationManageView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/orgsignup"
                element={
                    <OrganizationSignupView user={currentUser} />
                }
            ></Route>

            <Route
                path="/invite-user"
                element={
                    <ProtectedRoute>
                        <InviteUserView user={currentUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/tireconf"
                Component={() => {
                    // TODO: move this logic to the component
                    const query = window.location.search;
                    const params = new URLSearchParams(query);
                    // const deviceID = params.get('device');
                    const assetId = params.get('asset');
                    const nextView = params.get('nextView');
                    if (!assetId) {
                        return <p>Invalid request</p>;
                    }
                    if (currentUser) {
                        return (
                            <ConfigureTireSizeView assetId={assetId} nextView={nextView} />
                        );
                    } else {
                        return <Navigate to="/login" replace />;
                    }
                }}
            ></Route>

            <Route
                path="/forgotpassword"
                element={<ForgotPasswordRequestForm />}
            ></Route>

            <Route
                path="/confirm-email"
                element={<VerifyEmailView />}
            ></Route>

            <Route
                path="/must-confirm-email"
                element={
                    (currentUser) ? (
                        <SendEmailConfirmationView currentUser={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/user/verifyEmailByLink/:verificationCode/:emailAddress"
                element={<VerifyEmailForm />}
            >
            </Route>

            <Route
                path="/forgot"
                element={<ForgotPasswordHelper />}
            ></Route>

            <Route path="/account-deletion" element={<AccountDeleteView />}></Route>

            <Route path='/prices' element={
                <ProtectedRoute>
                    <PricingForm />
                </ProtectedRoute>
            }></Route>

            <Route path='/subscribe' element={
                <ProtectedRoute>
                    <SubscriptionCheckoutForm />
                </ProtectedRoute>
            }></Route>

            <Route path="/subscription-cancel" element={
                <ProtectedRoute>
                    <SubscriptionCancelView />
                </ProtectedRoute>
            }></Route>

            <Route path="/add-payment-method" element={
                <ProtectedRoute>
                    <AddPaymentMethod />
                </ProtectedRoute>
            }></Route>
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <HomePage />
                    </ProtectedRoute>
                }
            ></Route>
            <Route
                path="/link-hubo-asset/:assetId" element={currentUser ? <LinkHuboFlow user={currentUser}/> : <Navigate to="/login" />}
            ></Route>
        </Routes>

    );
};

export default MainRoutes;
