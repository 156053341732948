import React from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';

import styles from '../panels/DeviceDetailPanel.module.css';

import MileageChart from '../components/charts/MileageChart';
import GoogleMapPane from '../components/maps/GoogleMapPane';

import httpClient from "../httpClient";

function PanelCard(props) {

    return (
        <Card className={styles.DeviceCard}>
            <CardContent>
                <Grid container alignItems="center" justify="center">
                    {props.children}
                </Grid>
            </CardContent>
        </Card>
    )
}

function AssetDetailPanel(props) {
    let deviceMiles;
    if (props.asset.currentMileage !== undefined) {
        // deviceMiles = revsToMiles(props.asset.mostRecentData.revs, props.asset.revsPerMile).toFixed(1);
        deviceMiles = props.asset.currentMileage.toFixed(1);
    } else {
        deviceMiles = '-';
    }

    let lifetimeMiles;
    if (props.asset.lifetimeMileage !== undefined) {
        lifetimeMiles = props.asset.lifetimeMileage.toFixed(1);
    } else {
        lifetimeMiles = '-';
    }

    let deviceRevs = '-';
    if (props.asset.lifetimeRevs !== undefined) {
        deviceRevs = props.asset.lifetimeRevs;
    }

    let postTime = '-';
    if (props.asset.mostRecentData && props.asset.mostRecentData.revsUpdate) {
        postTime = new Date(props.asset.mostRecentData.revsUpdate).toString();
    }

    // Device-specific data
    // let batteryVoltage = '-';
    // let temperature = '-';
    // let harvesterFirmwareVersion = '-';
    // let cloudModuleFirmwareVersion = '-';
    // if (props.asset && props.asset.mostRecentData && props.asset.mostRecentData.devstatus) {
    //     const splitStatus = props.asset.mostRecentData.devstatus.split(',');
    //     batteryVoltage = ((splitStatus[0] / 100.0) + 2.0).toFixed(2);
    //     temperature = splitStatus[1] - 60.0;
    //     try {
    //         harvesterFirmwareVersion = '0x' + Number(splitStatus[2]).toString(16).toUpperCase();
    //     } catch {
    //     }
    //     try {
    //         cloudModuleFirmwareVersion = splitStatus[3];
    //     } catch {
    //     }
    // }

    let locationUpdateTime = '-';
    if (props.asset && props.asset.lastLocation && props.asset.lastLocation.ts) {
        locationUpdateTime = new Date(props.asset.lastLocation.ts).toString();
    }

    const lastResetTime = props.asset.lastRevReset ? new Date(props.asset.lastRevReset).toLocaleString() : undefined;

    //Maps element styleing
    const mapStyle = {
        // maxWidth: '450px',
        height: '240px',
        overflowX: "hidden",
        overflowY: "hidden"
    };
    const containerStyle = {
        height: '240px',
        // maxWidth: '450px'
    };

    return (
        <Box>
            <h4>Mileage</h4>
            <PanelCard>
                <Grid item sm={3}>
                    <p>POST TIME</p>
                    <h4>{postTime}</h4>
                </Grid>
                <Grid item sm={2}>
                    <p>ODOMETER (mi)</p>
                    <h3>{deviceMiles}</h3>
                    {(lastResetTime && lastResetTime !== 'Invalid Date') ?
                        <p><small><i>Last reset on {lastResetTime}</i></small></p>
                        : <br></br>
                    }
                    <Button
                        variant="contained"
                        onClick={props.onResetOdometer}
                        type="button"
                        disabled={!httpClient.currentUserIsOrgAdmin()}
                    >
                        Reset Odometer
                    </Button>

                    {/* Reset mileage button */}
                </Grid>
                <Grid item sm={2}>
                    <p>LIFETIME MILEAGE (mi)</p>
                    <h3>{lifetimeMiles}</h3>
                    <br></br>
                    <p>LIFETIME REVOLUTIONS</p>
                    <h3>{deviceRevs}</h3>
                </Grid>
                <Grid item sm={5}>
                    {
                        (props.asset !== undefined && props.revData) ?
                            <MileageChart revData={props.revData} device={props.asset} />
                            : <h4>No Historical Data</h4>
                    }
                </Grid>
                <Grid item sm={3} />
                <Grid item sm={2}>

                </Grid>
            </PanelCard>
            {/* <Card className={styles.DeviceCard}>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <p>DISTANCE (mi)</p>
                <h3>{deviceMiles}</h3>
              </Col>
              <Col sm={3}>
                <p>LIFETIME REVOLUTIONS</p>
                <h3>{deviceRevs}</h3>
              </Col>
              <Col sm={6}>
                <p>POST TIME</p>
                <h3>{postTime}</h3>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={3}>
                <p>BATTERY VOLTAGE (V)</p>
                <h3>{batteryVoltage.toFixed(2)}</h3>
              </Col>
              <Col sm={3}>
                <p>INTERNAL TEMPERATURE (&deg;C)</p>
                <h3>{temperature}</h3>
              </Col>
              <Col sm={3}>
                <p>ENERGY HARVESTER FIRMWARE VERSION</p>
                <h3>{harvesterFirmwareVersion}</h3>
              </Col>
              <Col sm={3}>
                <p>CLOUD MODULE FIRMWARE VERSION</p>
                <h3>{cloudModuleFirmwareVersion}</h3>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}
            <br />
            {/* <h4>Historical Data</h4>
        {
          (props.revData.length) ?
          <MileageChart revData={props.revData} revsPerMile={props.asset.revsPerMile}/>
          : <h4>No Data</h4>
        }
        <br /> */}
            <h4>Current Location</h4>
            {/* <DeviceMap devices={[props.asset]} /> */}
            <PanelCard>
                <Grid item sm={3}>
                    <p>POST TIME</p>
                    <h4>{locationUpdateTime}</h4>
                    <hr></hr>
                    <Link to={'/assettriphistory/' + props.asset._id}>
                        <Button size='large' variant="contained">View Location History</Button>
                    </Link>
                </Grid>
                <Grid item sm={1} />
                {/* Spacer */}
                {props.asset?.lastLocation ? (<Grid item sm={8}>
                    <GoogleMapPane devices={[props.asset]} lastLocation={props.asset.lastLocation} mapStyle={mapStyle} containerStyle={containerStyle} assetMode={true} />
                </Grid>) : <Grid item sm={8} ><Typography variant='h6'>No location data available</Typography></Grid>}
            </PanelCard>
            <br />
            {/* Move to device-specific view */}
            {/* {props.user?.admin ? (
                <>
                    <h4>Device Status</h4>
                    <PanelCard>
                        <Grid item sm={3}>
                            <p>BATTERY VOLTAGE (V)</p>
                            <h3>{batteryVoltage}</h3>
                        </Grid>
                        <Grid item sm={3}>
                            <p>INTERNAL TEMPERATURE (&deg;C)</p>
                            <h3>{temperature}</h3>
                        </Grid>
                        <Grid item sm={3}>
                            <p>ENERGY HARVESTER FIRMWARE VERSION</p>
                            <h3>{harvesterFirmwareVersion}</h3>
                        </Grid>
                        <Grid item sm={3}>
                            <p>CLOUD MODULE FIRMWARE VERSION</p>
                            <h3>{cloudModuleFirmwareVersion}</h3>
                        </Grid>
                    </PanelCard>
                </>
            ) : ''} */}
        </Box>
    )
}

export default AssetDetailPanel;