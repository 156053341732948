import React, { useState, useEffect } from 'react';
import { Button, Box, Paper, Typography, Select, MenuItem } from '@mui/material';
import httpClient from "../httpClient";
import { useNavigate, useParams } from 'react-router-dom';

const LinkHuboFlow = (props) => {
    // const asset = props.asset;
    const { assetId } = useParams();
    const [asset, setAsset] = useState();
    const user = props.user;
    const currentHubo = asset?.boundDevice;
    const [step, setStep] = useState((currentHubo) ? 1 : 2);
    const [devices, setDevices] = useState([]);
    const [gotDevices, setGotDevices] = useState(false);
    const [selectedHubo, setSelectedHubo] = useState(null);
    // const [currentHubo, setCurrentHubo] = useState(asset?.boundDevice);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return;
        }
        if (!asset) {
            httpClient.get('/asset/' + assetId).then((response) => {
                setAsset(response.data.asset);
            }).catch((err) => {
                console.log("Error getting asset by id:");
                console.log(err);
            });
        }
        if (!gotDevices) {
            httpClient.get('/device/').then((response) => {
                setDevices(response.data.devices.filter(device => device.organization === user.organization._id));
                setGotDevices(true);
            }).catch(() => {
                alert("Failed to fetch Hubos.");
            });
        }
    }, [assetId, asset, gotDevices, user, user?.organization?._id]);

    const handleHuboSelect = (hubo) => {
        if (hubo.boundAsset?._id === asset._id) {
            alert("The selected Hubo is already bound to this Asset.");
            return;
        }
        setSelectedHubo(hubo);
        if (hubo.boundAsset) {
            setStep(3); // Proceed to confirmation if already bound
        } else if (hubo === "") {
            setStep(5); // unlink asset from hubo
        } else {
            setStep(4); // Proceed to final linking step
        }
    };

    const performLinking = () => {
        httpClient.post('/asset/assign-device', { assetId: asset._id, deviceID: selectedHubo.deviceID })
            .then(() => {
                alert("Hubo successfully linked!");
                navigate(`/assetdetail/${asset._id}`);
            })
            .catch(() => {
                alert("Error linking Hubo.");
            });
    };

    const performUnlinking = () => {
        httpClient.post(
            '/asset/unassign-device',
            {
                assetID: asset._id,
                deviceID: asset.boundDevice.deviceID
            }
        ).then(() => {
            alert("Hubo sucessfully unlinked!");
            // navigate
        }).catch((reason) => {
            // if (reason.status === '404') alert("The selected device or asset was not found")
        })
    }

    return (
        <Box >
            {step === 1 ? (
                <Paper>
                    <Typography>
                        The Hubo with IMEI {currentHubo.imei} is already linked with this Asset.
                    </Typography>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => navigate(`/assetdetail/${asset._id}`)}>Cancel
                    </Button>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => setStep(2)}>Continue
                    </Button>
                </Paper>
            ) : step === 2 ? (
                <Paper>
                    <Typography>Select a Hubo to link with this asset:</Typography>
                    <Select
                        placeholder='select a hubo to link'
                        style={{ display: "flex", marginTop: "8px" }}
                        value={selectedHubo ? selectedHubo.deviceID : ""}
                        onChange={(e) => handleHuboSelect(devices.find(device => device.deviceID === e.target.value))}
                    >
                        <MenuItem value="">None</MenuItem>
                        {devices.map(device => (
                            <MenuItem key={device.deviceID} value={device.deviceID}>
                                {device.deviceID} {device.boundAsset ? `(bound to ${device.boundAsset.name})` : "(available)"}
                            </MenuItem>
                        ))}
                    </Select>

                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => navigate(`/assetdetail/${asset._id}`)}>Cancel
                    </Button>
                </Paper>
            ) : step === 3 ? (
                <Paper>
                    <Typography>
                        The selected Hubo {selectedHubo.imei} is already bound to the Asset {selectedHubo.boundAsset.name}.
                        Linking this Hubo with the current Asset {asset.name} will remove it from {selectedHubo.boundAsset.name}.
                        Do you wish to do this?
                    </Typography>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => setStep(2)}>Cancel
                    </Button>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={performLinking}>Yes
                    </Button>
                </Paper>
            ) : step === 5 ? (
                <Paper>
                    <Typography>
                        The selected Hubo {selectedHubo.imei} is already bound to the Asset {selectedHubo.boundAsset.name}.
                        Do you wish Remove this?
                    </Typography>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => setStep(2)}>Cancel
                    </Button>
                    <Button
                        style={{ marginTop: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={performUnlinking}>Yes
                    </Button>
                </Paper>
            ): step === 4 ? (
                <Paper style={{ marginTop: "8px" }}>
                    <h4>
                        Link Hubo <strong>{selectedHubo.deviceID}</strong> to Asset {asset.name}?
                    </h4>
                    <Button
                        style={{ marginRight: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={performLinking}>Confirm
                    </Button>
                    <Button
                        style={{ marginLeft: "8px" }}
                        variant='contained'
                        type="button"
                        onClick={() => navigate(`/assetdetail/${asset._id}`)}>Cancel
                    </Button>
                </Paper>
            ) : null}
        </Box>
    );
};

export default LinkHuboFlow;